<template>

    <a-card
      :class="disabledCard || errorCard"
      class="service-card"
    >
      <template #title>
        <a-checkbox @change="toggleActive" v-show="showExtendedMode" :checked="service.isActive"/>
        {{$t(service.name)}}
      </template>

      <template #extra>
        <span>
          {{service.bandwidth}} Mbps
          <a-popover trigger="hover">
            <template #content>
              <p><b>{{$t(service.name)}}</b></p>
              <p>Number of users: {{service.numberOfUsers}}</p>
              <p>Requests for service: {{service.intensityOfRequests}} requests/hour</p>
              <p>Session duration: {{service.serviceSessionDuration}} seconds</p>
              <p>Expected load: {{service.expectedLoadForService}} Erlang</p>
              <p>QoE degradation probability: {{service.expectedDegradationProbability * 100}}%</p>
              <p>Number of simultaneous sessions: {{service.numberOfSessions}} sessions</p>
            </template>
            <a-icon type="question-circle" />
          </a-popover>
        </span>
      </template>

      <div v-if="!showAdvancedMode">
        <p>Transfer rate (bit-rate): {{service.dataTransferRate}} Mbit/s</p>
        <p>Delay: {{service.latency}} ms</p>
        <p>Intensity per user: {{service.intensityPerUser}} requests/hr</p>
        <p>Amount of session data per hour: {{service.dataPerSession}} Mb</p>
      </div>
      <div v-if="showAdvancedMode">
        <a-row>
          <a-col span="11">Transfer rate (bit-rate)</a-col>
          <a-col span="13">
            <a-input
              :disabled="!service.isActive"
              :value="service.dataTransferRate"
              @blur="updateDataTransferRate"
              @pressEnter="updateDataTransferRate"
            >
              <template #addonAfter>
                Mbit/s
              </template>

            </a-input>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="11">Delay</a-col>
          <a-col span="13">
            <a-input
              :value="service.latency"
              :disabled="!service.isActive"
              @blur="updateLatency"
              @pressEnter="updateLatency"
            >
              <template #addonAfter>
                ms
              </template>            
            </a-input></a-col>
        </a-row>
        <a-row>
          <a-col span="11">Intensity per user:</a-col>
          <a-col span="13">
            <a-input
              :value="service.intensityPerUser"
              :disabled="!service.isActive"
              @blur="updateIntensity"
              @pressEnter="updateIntensity"
              addonAfter="requests/hr"
            >
            </a-input>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="11">Amount of session data per hour:</a-col>
          <a-col span="13">
            <a-input
              :value="service.dataPerSession"
              :disabled="!service.isActive"
              @blur="updateDataPerSession"
              @pressEnter="updateDataPerSession"
              addonAfter="Mb"
            >
            </a-input>
          </a-col>
        </a-row>
      </div>

    </a-card>
</template>
<script>

import locale from '../locale';
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      locale: locale,
      enabled: true,
    };
  },
  props: {
      service: {
        type: Object
      }
  },
  methods: {
    ...mapActions(['calculateActiveServices']),
    
    updateDataTransferRate(e) {
      this.service.dataTransferRate = e.target.value;
      this.updateData();
    },
    updateLatency(e) {
      this.service.latency = e.target.value;
      this.updateData();
    },
    updateIntensity(e) {
      this.service.intensityPerUser = e.target.value;
      this.updateData();
    },
    updateDataPerSession(e) {
      this.service.dataPerSession = e.target.value;
      this.updateData();
    },

    updateData() {
        this.$forceUpdate();
        this.calculateActiveServices()
    },

    toggleActive(e) {
      this.service.isActive = e.target.checked;
      this.calculateActiveServices();
    },
  },
  computed: {
    ...mapGetters(['selectedSettings']),
    showExtendedMode() {
      return ['EXTENDED', 'ADVANCED'].includes(this.selectedSettings.calculationMode);
    },
    showAdvancedMode() {
      return this.selectedSettings.calculationMode === 'ADVANCED';
    },
    errorCard() {
      return this.service.hasError ? 'error' : '';
    },
    disabledCard() {
      return !this.service.isActive ? 'disabled' :  '';
    },
  },
};
</script>

<style>

.disabled {
    color: rgb(202, 202, 202);
}

.error {
  border: 1px solid #b91f1f;
}

.input {
  align-content: inherit;
}

.ant-input {
  width: 5rem;
}
</style>