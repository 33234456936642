<template>
    <div class="save-results">
        <a-button class="control-button" :disabled="isShareDataDisabled" @click="showShareForm" type="primary" shape="round" icon="mail" size="large">{{$t('CONTROLS.SEND_DATA')}}</a-button>
        <a-button class="control-button" :disabled="isShareDataDisabled" @click="savePDF" type="primary" shape="round" icon="file-pdf" size="large">{{$t('CONTROLS.SAVE_AS_PDF')}}</a-button>
        <a-button class="control-button" :disabled="isShareDataDisabled" @click="saveCSV" type="primary" shape="round" icon="file-excel" size="large">{{$t('CONTROLS.SAVE_AS_CSV')}}</a-button>
        <a-button class="control-button" @click="dropSettings" type="primary" shape="round" icon="delete" size="large">{{$t('CONTROLS.CLEAR_SETTINGS')}}</a-button>

        <a-modal
          title="Share your data with the developers"
          :visible="shareFormVisible"
          @ok="sendData"
          @cancel="cancelSharing"
          :ok-text="$t('SHARE_FORM.OK')"
          :cancel-text="$t('SHARE_FORM.CANCEL')"
          :confirm-loading="shareFormLoading"
        >
          <p>
            {{$t('SHARE_FORM.HELP_TO_IMPROVE')}}
             <a-form>
              <a-form-item label="Email (optional)">
                <a-input
                  :placeholder="$t('SHARE_FORM.EMAIL')"
                  v-model="shareDataForm.email"
                  type="email"/>
              </a-form-item>
              <a-form-item label="Message (optional)">
                <a-textarea
                  v-model="shareDataForm.message"
                  placeholder="Leave your feedback"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
                </a-form-item>
            </a-form>      
          </p>
        </a-modal>
    </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import {mapActions, mapGetters} from "vuex";
import { uuidv4 } from '@/utils'

export default {
    name: 'SaveAs',
    data() {
        return {
          shareDataForm: {
            email: '',
            message: ''
          },
          pdfLineCursor: 10,
          shareFormVisible: false,
          shareFormLoading: false,
          PAGE_HEIGHT: 297,
          PAGE_WIDTH: 210,
          id: uuidv4()
        }
    },
    methods: {
      ...mapActions(['resetState']),
      showShareForm() {
        this.shareFormVisible = true;
      },
      async sendData() {
        this.shareFormLoading = true;

        const data = {
          id: this.id,
          contactEmail: this.shareDataForm.email,
          message: this.shareDataForm.message,
          numberOfUsers: this.selectedSettings.numberOfUsers,
          objectType: this.selectedSettings.objectType.name,
          trafficProfile: this.selectedSettings.trafficProfile, // ?????? not work
          services: this.selectedSettings.objectType.services.map((service) => ({
            name: service.name,
            dataPerSession: service.dataPerSession,
            dataTransferRate: service.dataTransferRate,
            latency: service.latency,
            intensityPerUser: service.intensityPerUser,
            isActive: service.isActive
          })),
        };

        try {
          await fetch(this.statUrl, {
            method: 'POST',
            body: JSON.stringify(data)
          });
          this.$notification['success']({
            message: 'Thank you',
            description:
              'The data you\'ve sent will be used to improve calculation',
          });
        } catch (e) {
          this.$notification['error']({
            message: 'Something went wrong',
            description:
              `An error occurred: ${e}`,
          });
        } finally {
          this.shareFormVisible = false;
          this.shareFormLoading = false;
        }
      },
      cancelSharing() {
        this.shareFormVisible = false;
        this.shareFormLoading = false;
      },
      printPDFLine(doc, text, fontSize=12, xOffset=10, interline=8) {
        doc.setFontSize(fontSize);
        doc.text(text, xOffset, this.pdfLineCursor);
        this.pdfLineCursor+=interline;
        if (this.pdfLineCursor >= this.PAGE_HEIGHT) {
          doc.addPage();
          this.pdfLineCursor = 10;
        }
      },
      printPDFWithoutNewLine(doc, text, fontSize=12, xOffset) {
        doc.setFontSize(fontSize);
        doc.text(text, xOffset, this.pdfLineCursor);
      },
      savePDF() {

        const doc = new jsPDF({
          unit: "mm",
          format: [this.PAGE_WIDTH, this.PAGE_HEIGHT]
        });
        doc.setFont('OpenSans-Regular', 'normal');

        this.printPDFLine(doc, `${this.$t('REPORT.ESTIMATED_BANDWIDTH')}: ${this.estimatedBandwidth} Mbps`, 18, 10);
        this.printPDFLine(doc, this.$t('REPORT.SETTINGS'), 16, 10, 8);
        this.printPDFLine(doc, `${this.$t('REPORT.SELECTED_SCOPE')}: ${this.$t(this.selectedSettings.scope.name)}`, 10, 10, 5);
        this.printPDFLine(doc, `${this.$t('REPORT.SELECTED_OBJECT')}: ${this.$t(this.selectedSettings.objectType.name)}`, 10, 10, 5);
        this.printPDFLine(doc, `${this.$t('DESCRIPTIONS.NUMBER_OF_USERS')}: ${this.selectedSettings.numberOfUsers}`, 10, 10, 5);
        this.printPDFLine(doc, '', 16, 10, 6);
        this.printPDFLine(doc, this.$t('REPORT.ACTIVE_SERVICES'), 16, 10, 6);

        for(const service of Object.values(this.selectedSettings.objectType.services)) {
          if (service.isActive) {

            this.printPDFLine(doc, `${this.$t(service.name)} ${service.bandwidth} Mbps`, 14, 10, 5);

            this.printPDFWithoutNewLine(doc, `Data transfer rate: ${service.dataTransferRate} Mbps`, 10, 20, 5);
            this.printPDFLine(doc, `Requests for service: ${service.intensityOfRequests} requests/hour`, 10, this.PAGE_WIDTH/2, 5);

            this.printPDFWithoutNewLine(doc, `Delay: ${service.latency} ms`, 10, 20, 5)
            this.printPDFLine(doc, `Session duration: ${service.serviceSessionDuration} seconds`, 10, this.PAGE_WIDTH/2, 5)

            this.printPDFWithoutNewLine(doc, `Intensity per user: ${service.intensityPerUser} requests/hour`, 10, 20, 5)
            this.printPDFLine(doc, `Expected load: ${service.expectedLoadForService} Erlang`, 10, this.PAGE_WIDTH/2, 5)

            this.printPDFWithoutNewLine(doc, `Data per session: ${service.dataPerSession} Mb`, 10, 20, 5)
            this.printPDFLine(doc, `Number of simultaneous sessions: ${service.numberOfSessions}`, 10, this.PAGE_WIDTH/2, 8)

          }
        }


        this.pdfLineCursor = 10;

        doc.save("bandwidth_calculation_results.pdf");
      },
      saveCSV() {

        //define the heading for each row of the data
        let csv = '"Selected Scope";"Selected Object";"Number of users";"Estimated bandwidth"\n';
            csv += `"${this.$t(this.selectedSettings.scope.name)}";`;
            csv += `"${this.$t(this.selectedSettings.objectType.name)}";`;
            csv += `" ${this.selectedSettings.numberOfUsers}";`;
            csv += `" ${this.estimatedBandwidth}";`;
            csv +=`\n\n\n\n`;

        csv += '"Service Name";"Data Transfer Rate";"Latency";"Intensity per User";"Data per Session";"Requests for service";"Session duration";"Expected load";"Number of simutaneous sessions"\n';  
        //merge the data with CSV  

        for(const service of Object.values(this.selectedSettings.objectType.services)) {
          if (service.isActive) {
            csv += `"${this.$t(service.name)}\t";`;
            csv += `"${this.$t(service.dataTransferRate)}\t";`;
            csv += `"${this.$t(service.latency)}\t";`;
            csv += `"${this.$t(service.intensityPerUser)}\t";`;
            csv += `"${this.$t(service.dataPerSession)}\t";`;
            csv += `"${this.$t(service.intensityOfRequests)}\t";`;
            csv += `"${this.$t(service.serviceSessionDuration)}\t";`;
            csv += `"${this.$t(service.expectedLoadForService)}\t";`;
            csv += `"${this.$t(service.numberOfSessions)}\t";`;
            csv += "\n";
          }
        }


        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "bandwidth_calculation_results.csv", { autoBom: true })

      },
      dropSettings() {
        this.$root.$emit('clearSettings');
        this.resetState();
      }
    },
    computed: {
      ...mapGetters(['selectedSettings', 'estimatedBandwidth', 'statUrl']),
      isShareDataDisabled() {
        return !this.selectedSettings?.objectType?.services?.length;
      }
    }
}
</script>
<style scoped>
.ant-btn {
    margin: 1rem;
}

.save-results {
  text-align: center
}

.control-button {
  width: min-content;
}

</style>
