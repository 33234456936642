<template>
  <div class="calculation-mode-picker">
    <b>{{$t('DESCRIPTIONS.CALCULATION_MODE')}}</b>
    <div>
      <a-radio-group v-model="selectedMode" button-style="solid" @change="setCalculationMode">
        <a-radio-button class="item" v-for="(mode) in calculationModes" :key="mode.key" :value="mode">
          {{mode.name}}
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'CalculationMode',
  data() {
    return {
      selectedMode: 'SIMPLE',
      calculationModes: [
        {
          name: this.$t('CALCULATION_MODES.SIMPLE'),
          key: 'SIMPLE'
        },
        {
          name: this.$t('CALCULATION_MODES.EXTENDED'),
          key: "EXTENDED"
        },
        {
          name: this.$t('CALCULATION_MODES.ADVANCED'),
          key: 'ADVANCED'
        },
      ],

    }
  },
  computed: {
    ...mapGetters(['selectedSettings'])
  },
  mounted () {
    this.$root.$on('clearSettings', this.onClearSettings)
  },
  beforeDestroy () {
    this.$root.$off('clearSettings', this.onClearSettings)
  },
  methods: {
    setCalculationMode(e) {
      this.$store.commit('SET_CALCULATION_MODE', e.target.value.key);
    },
    onClearSettings() {
      this.selectedMode = 'SIMPLE';
      this.$store.commit('SET_CALCULATION_MODE', 'SIMPLE');
    }
  }
}
</script>
<style scoped>

</style>
