import {
    calculateServiceSessionDuration,
    calculateIntensityOfRequests,
    calculateExpectedLoadForService,
    calculateNumberOfSessions,
    calculateBandwidth,
} from "@/bandwidth";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

function round(number, precision=3) {
    return Number(number.toFixed(precision))
}

class Scope {
    constructor(name) {
        this.name = name;
        this.objects = {};
    }

    addObject(object) {
        this.objects[object.name] = object;
        return this;
    }
}

class CalculationObject {
    constructor(name) {
        this.name = name;
        this.generalConnectivityProfiles = {};
        this.customServices = [];
    }

    addGeneralConnectivityProfile(profile) {
        this.generalConnectivityProfiles[profile.name] = profile;
        return this;
    }

    addCustomService(service) {
        this.customServices.push(service);
    }

    get services() {
        const services = {};
        for (const profile of Object.values(this.generalConnectivityProfiles)) {
            for(const svc of Object.values(profile.services)) {
                services[svc.name] = svc;
            }
        }
        return Object.values(services).concat(this.customServices);
    }
}

class Service {
    constructor(props) {
        this.name = props.name;
        this._isActive = true;
        this._hasError = false;
        this._dataTransferRate = props.dataTransferRate || [0, 0, 0]; // 2	Enough bitrate for service usage with a given level of quality	V	Mbps
        this._latency = props.latency || [0, 0, 0];
        this._intensityPerUser = props.intensityPerUser || [0, 0, 0]; // 3	Service usage intensity by one user (subscriber) – the number of requests	I	request per hour
        this._dataPerSession = props.dataPerSession || [0, 0, 0]; // 1	Information volume by one standard session for a service	L	MB
        this._expectedDegradationProbabilities = props.expectedDegradationProbabilities || [0, 0, 0]; // predefined. value is in %. need to divide by 100 for calculations

        this._numberOfUsers = 1
        this._trafficProfile = {
            quality: 0,
            intensity: 0,
        }
    }

    isValidValue(value) {
        const reg = /^-?[0-9]*(\.[0-9]*)?$/;

  
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            return true;
        }

        return false;
    }

    set numberOfUsers(val) {
        this._numberOfUsers = val;
    }

    set trafficProfile(val){
        this._trafficProfile = val;
    }

    set dataTransferRate(val) {

        if (this.isValidValue(val)) {
            this._dataTransferRate = Array(3).fill(Number(val));
        } else {
            console.log('VALUE NOT VALID')
        }
        
    }

    set latency(val) {
        if (this.isValidValue(val)) {
            this._latency = Array(3).fill(Number(val));
        }
    }

    set intensityPerUser(val) {
        if (this.isValidValue(val)) {
            this._intensityPerUser = Array(3).fill(Number(val));
        }
    }

    set dataPerSession(val) {
        if (this.isValidValue(val)) {
            this._dataPerSession = Array(3).fill(Number(val));
        }
    }

    set isActive(val) {
        this._isActive = val;
    }

    set hasError(val) {
        this._hasError = val;
    }

    get isActive() {
        return this._isActive;
    }

    get hasError() {
        return this._hasError;
    }

    get numberOfUsers() {
        return this._numberOfUsers;
    }

    get dataTransferRate() {
        return this._dataTransferRate[this._trafficProfile.quality];
    }

    get latency() {
        return this._latency[this._trafficProfile.quality];
    }

    get intensityPerUser() {
        return this._intensityPerUser[this._trafficProfile.intensity];
    }

    get dataPerSession() {
        return this._dataPerSession[this._trafficProfile.intensity];
    }

    get expectedDegradationProbability() {
        return this._expectedDegradationProbabilities[this._trafficProfile.quality]/100;    // we have values in %. need to convert
    }

    get serviceSessionDuration () {
        return this.isActive ? round(calculateServiceSessionDuration(this.dataPerSession, this.dataTransferRate)) : 0;
    }

    get intensityOfRequests () {
        return this.isActive ? round(calculateIntensityOfRequests(this.numberOfUsers, this.intensityPerUser)) : 0;
    }

    get expectedLoadForService () {
        return this.isActive ? round(calculateExpectedLoadForService(this.intensityOfRequests, this.serviceSessionDuration)) : 0;
    }

    get numberOfSessions () {
        return this.isActive ? round(calculateNumberOfSessions(this.expectedDegradationProbability, this.expectedLoadForService)) : 0;
    }

    get bandwidth () {
        return this.isActive ? round(calculateBandwidth(this.numberOfSessions, this.dataTransferRate)) : 0;
    }
}

class GeneralConnectivityProfile {
    constructor(name) {
        this.name = name;
        this.services = [];
    }

    addService(service) {
        this.services.push(service)
        return this;
    }
}


function initGeneralConnectivityProfiles(generalConnectivityProfiles, services) {
    const profiles = {};
    for(const [name, profileServices] of Object.entries(generalConnectivityProfiles)) {
        profiles[name] = new GeneralConnectivityProfile(name);
        for(const service of profileServices) {
            profiles[name].addService(services[service])
        }
    }


    return profiles;
}

function initData(scopes, generalConnectivityProfiles) {
    const data = {};
    for(const [scopeKey, scopeData] of Object.entries(scopes)) {
        data[scopeKey] = new Scope(scopeKey);
        for(const [objectKey, objectData] of Object.entries(scopeData)) {
            data[scopeKey].addObject(new CalculationObject(objectKey));
            for (const profile of objectData?.GENERAL_CONNECTIVITY_PROFILES) {
                data[scopeKey].objects[objectKey].addGeneralConnectivityProfile(generalConnectivityProfiles[profile])
            }
        }
    }

    return data;
}


function initServices(services, expectedDegradationProbabilities) {
    const svc = {};
    for(const [k, v] of Object.entries(services)) {
        svc[k] = new Service({
            name: k,
            dataTransferRate: v.DATA_TRANSFER_RATE,
            latency: v.LATENCY,
            intensityPerUser: v.INTENSITY_PER_USER,
            dataPerSession: v.DATA_PER_SESSION,
            expectedDegradationProbabilities: expectedDegradationProbabilities
        })
    }
    return svc;
}


export {
    initServices,
    initGeneralConnectivityProfiles,
    initData,
    Service,
    uuidv4
}
