import {initData, initGeneralConnectivityProfiles, initServices} from "@/utils";

const state = {
  initialData: {},
  statUrl: ''
};

const getters = {
  generalConnectivityProfiles: () => CALC_CONFIG.GENERAL_CONNECTIVITY_PROFILES,
  trafficClasses: () => CALC_CONFIG.TRAFFIC_CLASSES,
  scopes: () => CALC_CONFIG.SCOPES,
  expectedDegradationProbabilities: () => CALC_CONFIG.EXPECTED_DEGRADATION_PROBABILITIES,
  services: () => CALC_CONFIG.SERVICES,
  initialData: (store) => store.initialData,
  statUrl: () => STAT_URL,
};


const actions = {
  init: ({ commit }) => {
    const servicesList = initServices(getters.services(), getters.expectedDegradationProbabilities())
    const generalConnectivityProfiles = initGeneralConnectivityProfiles(getters.generalConnectivityProfiles(), servicesList);
    const initialData = initData(getters.scopes(), generalConnectivityProfiles);
    commit('SET_INITIAL_DATA', initialData)
  },
}

const mutations = {
  'SET_INITIAL_DATA': (state, payload) => {
      state.initialData = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};