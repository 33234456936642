import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/font/OpenSans-Regular-normal';
import VueI18n from "vue-i18n";
import locale from './locale'
import Vuex from 'vuex'

import store from './store/store';

Vue.config.productionTip = false

Vue.use(Antd);
Vue.use(VueI18n)
Vue.use(Vuex)



const i18n = new VueI18n({
  locale: store.getters.selectedLang,
  messages: locale,
});


window.app = new Vue({
  render: h => h(App),
  store,
  i18n,
}).$mount('#app')
