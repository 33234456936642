import Vue from 'vue';
import Vuex from 'vuex';

import config from '@/store/modules/config';
import calculator from "@/store/modules/calculator";
import locale from '@/store/modules/locale';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    config,
    calculator,
    locale,
  },
});