<template>
  <div class="services">
    <b>{{$t('DESCRIPTIONS.SERVICES')}}</b>
    <div class="services-cards">
      <service-card
          v-for="(service, key) in services"
          :key="key"
          :service="service"
          :serviceName="key"
          :card-mode="selectedSettings.calculationMode"
          class="card"
      />

      <a-card class="card service-card" v-if="selectedSettings.calculationMode === 'ADVANCED'">
        <template #title>
          {{$t('DESCRIPTIONS.ADD_CUSTOM_SERVICE')}}: <a-input v-model="newService.name"></a-input>
        </template>

        <template #extra>
          <span>
            <a-button icon="plus-circle"
              :disabled="isServiceNameEmpty"
              @click="addNewService"
            />
          </span>
        </template>
        <a-row>
          <a-col span="12">Transfer rate (bit-rate):</a-col>
          <a-col span="12"><a-input v-model="newService.dataTransferRate" addonAfter="Mbit/s"></a-input></a-col>
        </a-row>
        <a-row>
          <a-col span="12">Delay:</a-col>
          <a-col span="12"><a-input v-model="newService.latency" addonAfter="ms"></a-input></a-col>
        </a-row>
        <a-row>
          <a-col span="12">Intensity per user:</a-col>
          <a-col span="12"><a-input v-model="newService.intensityPerUser" addonAfter="requests/hr"></a-input></a-col>
        </a-row>
        <a-row>
          <a-col span="12">Amount of session data per hour:</a-col>
          <a-col span="12"><a-input v-model="newService.dataPerSession" addonAfter="Mb"></a-input></a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ServiceCard from "@/components/ServiceCard";

export default {
  name: 'ServicesList',
  components:{
    ServiceCard
  },
  data() {
    return {
      newService: {
        isActive: true,
        name: '',
        dataTransferRate: 0,
        intensityPerUser: 0,
        latency: 0,
        dataPerSession: 0
      }
    }
  },
  computed: {
    ...mapGetters(['selectedSettings', 'expectedDegradationProbabilities']),
    services() {
      return this.selectedSettings.objectType.services;
    },
    isServiceNameEmpty() {
      return !this.newService.name.length;
    }
  },

  methods: {
    ...mapActions(['calculateActiveServices']),
    addNewService() {
      this.$store.commit('ADD_SERVICE', {
        name: this.newService.name,
        dataTransferRate: Array(3).fill(this.newService.dataTransferRate),
        latency: Array(3).fill(this.newService.latency),
        intensityPerUser: Array(3).fill(this.newService.intensityPerUser),
        dataPerSession: Array(3).fill(this.newService.dataPerSession),
        expectedDegradationProbabilities: this.expectedDegradationProbabilities
      });
      this.calculateActiveServices()
    }
  }
}
</script>
<style scoped>
  .services-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .add-service-btn {
    width: 15vw;
    height: 12rem;
    align-content: center;
  }

  .add-card {
    display: flex;
    flex-direction: column;
  }

  .card {
    background: #f6f6f6;
    height: 15rem;
    flex: 1 1 30%;
    min-width: 27rem;
    max-width: 27rem;
  }

  @media (max-width:960px) {
    .card {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .input {
    align-content: inherit;
  }

  .ant-input {
    width: 5rem;
  }

</style>
