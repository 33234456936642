<template>
  <a-layout-header class="header">
    <a-avatar shape="circle" :size=" 50" :src="logoPath">
    </a-avatar>

    <a-dropdown>
      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
         {{availableLanguages[selectedLang]}}
         <a-icon type="down" />
      </a>
      <a-menu slot="overlay" @click="changeLanguage">
        <a-menu-item :key="key" v-for="(lang, key) in availableLanguages">
          {{availableLanguages[key]}}
        </a-menu-item>
      </a-menu>
    </a-dropdown>

  </a-layout-header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TopBar',
  data() {
      return {
        availableLanguages: {
          en: 'English',
          ru: 'Russian'
        },
      }
  },
  created() {
    console.log(this.selectedLang)
  },
  computed: {
    ...mapGetters(['selectedLang']),
    logoPath() {
      return require('@/assets/logo.png');
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage(e) {
      this.setLanguage(e.key);
    },
  }
}
</script>
