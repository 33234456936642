<template>
    <div class="object-type-picker">
        <b>{{$t('DESCRIPTIONS.OBJECT_TYPE')}}</b>
        <item-picker :items="objectTypeList" @change="onChange"/>
    </div>
</template>

<script>
import ItemPicker from '../../components/ItemPicker.vue'
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'ObjectTypePicker',
    components: {
        ItemPicker
    },
    data() {
        return {}
    },
    computed: {
      ...mapGetters(['selectedSettings', 'activeServices']),
      objectTypeList() {
        if(this.selectedSettings.scope) {
          return Object.values(this.selectedSettings.scope.objects)
        }
        return []
      }
    },
    methods: {
      ...mapActions(['calculateActiveServices']),
      onChange(e) {
        this.$store.commit('SET_OBJECT_TYPE', e)
        this.$store.commit('SET_TRAFFIC_PROFILE', this.selectedSettings.trafficProfile);
        this.calculateActiveServices();
      }
    }
}
</script>
<style scoped>

</style>
