function factorial(number) {
  return number > 1 ? number * factorial(number - 1) : 1
}

function calculateServiceSessionDuration (dataPerSession, dataTransferRate) {
  return (8.38 * dataPerSession)/dataTransferRate;
}

function calculateIntensityOfRequests(numberOfUsers, intensityPerUser) {
  return numberOfUsers * intensityPerUser;
}

function calculateExpectedLoadForService(intensityOfRequests, serviceSessionDuration) {
  return intensityOfRequests * (serviceSessionDuration/3600);
}

function calculateNumberOfSessions(expectedDegradationProbability, expectedLoadForService) {
  let sessions = 0;
  let degradationProbability = 1;
  while (degradationProbability > expectedDegradationProbability) {
    ++sessions;
    const nominator = Math.pow(expectedLoadForService, sessions)/factorial(sessions);
    let denominator = 0;

    for(let f=0; f<=sessions; ++f) {
      denominator += (Math.pow(expectedLoadForService, f))/factorial(f)
    }

    degradationProbability = nominator/denominator;
  }
  return --sessions || 1;
}

function calculateBandwidth (numberOfSessions, dataTransferRate) {
  return numberOfSessions * dataTransferRate;
}


module.exports = {
  calculateServiceSessionDuration,
  calculateIntensityOfRequests,
  calculateExpectedLoadForService,
  calculateNumberOfSessions,
  calculateBandwidth,
};