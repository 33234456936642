const state = {
  selectedLang:  localStorage.getItem('locale') || 'en',
}

const getters = {
  selectedLang: (state) => state.selectedLang,
};

const actions = {
  setLanguage: ({commit}, lang) => {
    commit('SET_LANG', lang);
  }
};

const mutations = {
  'SET_LANG': (state, payload) => {
    state.selectedLang = payload;
    localStorage.setItem('locale', payload)
    window.app.$i18n.locale = payload;
  },
}


export default {
  state,
  getters,
  actions,
  mutations
};