<template>
    <div class="scope-picker">
        <a-radio-group :default-value="defaultValue" v-model="checked" @change="onChange">
            <a-radio-button class="item" v-for="(item, index) in items" :key="index" :value="item">
              <a-avatar :size="22" v-if="getItemPic(item.name)" :src="getItemPic(item.name)" shape="square" class="pic"/>
              {{$t(item.name)}}
            </a-radio-button>
        </a-radio-group>
    </div>
</template>
<script>
export default {
  data() {
    return {
      checked: ''
    };
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
      items: {
          type: Array
      },
      defaultValue: {
        type: Object,
      }
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.value)
    },
    getItemPic(name) {
      try {
        return require(`@/assets/img/objects/${name.toLowerCase()}.svg`);
      } catch (e) {
        console.warn('Could not find image for', name)
      }
    }
  },
};
</script>
<style>
  .scope-picker {
    display: flex;
    flex-direction: column;
  }

  item {
    margin-top: 22px;
  }

@media (max-width:960px) {
  .scope-picker {
    display: flex;
    flex-direction: column;
  }
  .item {
    width: 90vw;
  }
}
</style>