<template>
    <a-layout-footer class="footer">
        <save-results/>
        Bandwidth Calculator {{ new Date().getFullYear() }}. All rights reserved.
    </a-layout-footer>
</template>

<script>

import SaveResults from "@/pages/BandwidthCalculator/SaveResults";

export default {
    name: 'BottomBar',
    components: {
        SaveResults
    },
    data() {
        return {}
    }
}
</script>
<style>
footer {
  text-align: center;
}

</style>