<template>
    <div id="calc">

        <p class="title">
            <b>{{$t('DESCRIPTIONS.TITLE')}}</b>
        </p>
        <p class="subtitle">

          {{$t('DESCRIPTIONS.SUBTITLE')}}
        </p>
        <estimated-bandwidth />
        {{$t('DESCRIPTIONS.ESTIMATE_BANDWIDTH_FOR')}}

        <scope-picker
            class="section"
        />
        <object-type-picker
            class="section"
            v-show="selectedSettings.scope"
        />
        <number-of-users-picker
            class="section"
            v-show="selectedSettings.objectType"
        />
        <traffic-profile-picker
            class="section"
            v-show="selectedSettings.objectType"
        />
        <!-- Calculation mode is disabled for now and always advanced -->
        <calculation-mode
            class="calculation-mode-picker section"
            v-show="false"
        />
        <services-list v-if="selectedSettings.objectType" class="section"></services-list>
    </div>
</template>

<script>
import EstimatedBandwidth from './EstimatedBandwidth.vue';
import ObjectTypePicker from './ObjectTypePicker.vue';
import TrafficProfilePicker from './TrafficProfilePicker.vue';
import NumberOfUsersPicker from './NumberOfUsersPicker.vue';
import ScopePicker from "@/pages/BandwidthCalculator/ScopePicker";
import {mapActions, mapGetters} from 'vuex';
import ServicesList from "@/pages/BandwidthCalculator/ServicesList";
import CalculationMode from "@/pages/BandwidthCalculator/CalculationMode";

export default {
  components: {
    CalculationMode,
    ServicesList,
    EstimatedBandwidth,
    ScopePicker,
    ObjectTypePicker,
    TrafficProfilePicker,
    NumberOfUsersPicker,
  },
    name: 'BandwidthCalculator',
    data() {
        return {
            objectTypes: {},
            servicesList: {},
        }
    },
    computed: {
      ...mapGetters(['estimatedBandwidth', 'initialData', 'calculationMode', 'selectedSettings']),
      objects () {
        return this.initialData[this.selectedSettings.scope]?.objects;
      },
    },
    methods: {
      ...mapActions(['init']),
    },
    created() {
      this.init();
      if (!sessionStorage.getItem("disclaimerAccepted")) {
        const h = this.$createElement;
        this.$warning({
          title: 'Beta version',
          content: h('div', {}, [
            h('p', 'Please note that you are accessing a beta  version of this software. Access to this beta version is for purposes of testing and improvement of the software. The software and its content are provided as is and do not give any guaranties of  availability, stability, accuracy, suitability or usability. The user will be solely responsible for the use and of this software and any of its consequences.'),
            h('p', 'Please input or adjust the data to continue.'),
            h('p', 'Click below if you accept the terms and conditions of using this software.'),
          ]),
          onOk() {
            sessionStorage.setItem("disclaimerAccepted", true);
          },
        });
      }
    }
}
</script>

<style scoped>

.title {
  text-align: center;
  font-size: 2.5rem;
}

.subtitle {
  text-align: justify;
  width: 90vw;
}

#calc {
  margin-top: 1rem;
}

.section {
  margin-top: 2rem;
}

</style>