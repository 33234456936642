<template>
    <div class="scope-picker">
        <b>{{ $t('DESCRIPTIONS.SCOPE') }}</b>
        <a-radio-group v-model="selectedScope" @change="onChange">
            <a-radio-button class="item" v-for="(scope) in scopes" :key="scope.name" :value="scope">
              <a-avatar :size="22" v-if="getItemPic(scope.name)" :src="getItemPic(scope.name)" shape="square" class="pic"/>
              {{$t(scope.name)}}
            </a-radio-button>
        </a-radio-group>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'ScopePicker',
    data() {
        return {
          selectedScope: ''
        }
    },
    computed: {
      ...mapGetters(['initialData']),
      scopes() {
        return Object.values(this.initialData);
      }
    },
    methods: {
      ...mapActions(['resetState']),
      getItemPic(name) {
        try {
          return require(`@/assets/img/objects/${name.toLowerCase()}.svg`);
        } catch (e) {
          console.warn('Could not find image for', name)
        }
      },
      onChange(e) {
        this.$store.commit('SET_SCOPE', e.target.value);
        this.$store.commit('SET_OBJECT_TYPE', '');
        this.$store.commit('SET_ESTIMATED_BANDWIDTH', 0);
      }
    }
}
</script>
<style scoped>

</style>
