<template>
    <div class="number-of-users-picker">
        <b>{{$t('DESCRIPTIONS.NUMBER_OF_USERS')}}</b>
        <div>
          <a-input-number 
            size="large"
            default-value="1"
            v-model="numberOfUsers"
            @change="onChange"
            @pressEnter="onChange"
            @clearSettings="onClearSettings"
            :min="1"
            :step="1"
          >
          </a-input-number>
        </div>
    </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
    name: 'NumberOfUsersPicker',
    data() {
      return {
        numberOfUsers: 1
      }
    },
    mounted () {
      this.$root.$on('clearSettings', this.onClearSettings)
    },
    beforeDestroy () {
      this.$root.$off('clearSettings', this.onClearSettings)
    },
    methods: {
      ...mapActions(['calculateActiveServices']),
      onChange() {
        this.numberOfUsers = Math.floor(this.numberOfUsers)
        this.$store.commit('SET_USER_NUMBER', this.numberOfUsers);
        this.calculateActiveServices();
      },
      onClearSettings() {
        this.numberOfUsers = 1;
      }
    },
}
</script>
<style scoped>
.ant-input-number {
  width: 15rem;
}


</style>
