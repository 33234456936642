const en = {
    DESCRIPTIONS: {
        TITLE: 'Bandwidth Calculator',
        SUBTITLE: 'To estimate required bandwidth for specific object please select general connectivity application profile, object type, number of users, quality and intensity levels.', // TEMPORARILY DISABLED Please switch to extended mode to be able to choose services and to advanced mode to set parameters for services manually.',
        ESTIMATED_BANDWIDTH: 'Estimated bandwidth',
        ESTIMATE_BANDWIDTH_FOR: 'Estimate required bandwidth for',
        CALCULATION_MODE: 'Calculation mode',
        SCOPE: 'Scope',
        OBJECT_TYPE: 'Object type',
        NUMBER_OF_USERS: 'Number of users',
        HOW_IT_WORKS: 'How it works',
        ADD_CUSTOM_SERVICE: 'Add custom service',
        QUALITY_LEVEL: 'Quality level',
        INTENSITY_LEVEL: 'Intensity level',
        LOW: 'Low',
        MEDIUM: 'Medium',
        HIGH: 'High',
        QUALITY: 'Quality',
        INTENSITY: 'Intensity',
        TRAFFIC_PROFILE: 'Traffic profile',
        SERVICES: 'Services'
    },
    SCOPES: {
        HOUSEHOLDS: 'Households',
        EDUCATION_SCIENCE: 'Education and Science',
        MEDICINE: 'Medicine',
    },
    SERVICES: {
        CONVERSATIONAL_VOICE: 'Conversational Voice',
        SPECIALIZED_SOFTWARE: 'Specialized software'
    },
    OBJECTS: {
        RURAL_OBJECT: 'Rural Object',
        URBAN_OBJECT: 'Urban Object',
        OFFICE: 'Office',
    },
    CALCULATION_MODES: {
        SIMPLE: 'Simple',
        ADVANCED: 'Advanced',
        EXTENDED: 'Extended'
    },
    SERVICE_CARD: {

    },
    CONTROLS: {
        SEND_DATA: 'Share data',
        SAVE_AS_PDF: 'Save as PDF',
        SAVE_AS_CSV: 'Save as CSV',
        CLEAR_SETTINGS: 'Clear Settings'
    },
    SHARE_FORM: {
        SHARE_DATA_WITH_DEV: 'Send your data to the developers',
        EMAIL: 'E-Mail',
        MESSAGE: 'Message',
        LEAVE_YOUR_FEEDBACK: 'Leave your feedback',
        HELP_TO_IMPROVE: 'Help us to improve our service. Share your data with us so that we can improve our parameters for more precise calculations. By clicking share button you send us the parameters you entered in advanced mode.',
        OK: 'Share',
        CANCEL: 'Cancel'
    },
    REPORT: {
        ESTIMATED_BANDWIDTH: 'Estimated bandwidth',
        SELECTED_SCOPE: 'Selected scope',
        SELECTED_OBJECT: 'Selected object type',
        SETTINGS: 'Selected settings',
        ACTIVE_SERVICES: 'Active services'
    },
    HOUSEHOLDS: 'Households',
    EDUCATION_SCIENCE: 'Education and Science',
    MEDICINE: 'Medicine',
    BUSINESS: 'Business',
    INDUSTRY: 'Industry',
    ADMINISTRATIVE: 'Administrative',
    CULTURE_SPORT: 'Culture and Sport',
    TRANSPORT: 'Transport',
    VEHICLE_REPAIR: 'Vehicle Repair',
    BUS_STATION: 'Bus Station',
    GAS_STATION: 'Gas Station',
    MUSEUM: 'Museum',
    CITY_PARK: 'City Park',
    RURAL_OBJECT: 'Rural object',
    URBAN_OBJECT: 'Urban object',
    SCHOOL: 'School',
    UNIVERSITY: 'University',
    RND_OFFICE: 'RnD Office',
    HOSPITAL: 'Hospital',
    MEDICAL_CENTER: 'Medical Center',
    STORE: 'Store',
    SUPERMARKET: 'Supermarket',
    BAKERY: 'Bakery',
    IT_OFFICE: 'IT Office',
    BANK: 'Bank',
    FARM: 'Farm',
    RESTAURANT: 'Restaurant',
    HOTEL: 'Hotel',
    WAREHOUSE: 'Warehouse',
    FACTORY: 'Factory',
    POST_OFFICE: 'Post Office',
    ADMINISTRATION_OBJECT: 'Administration Object',
    THEATRE: 'Theatre',
    STADIUM: 'Stadium',

    SIMPLE: 'Simple',
    ADVANCED: 'Advanced',
    EXTENDED: 'Extended',

    DATA_TRANSFER_RETRIEVAL: 'Data transfer/retrieval',
    HIGH_QUALITY_STREAMING_AUDIO: 'High quality streaming audio',
    VIDEO_CONFERENCE: 'Video conference',
    BROADCAST: 'Broadcast',
    VIDEO_STREAMING: 'Video streaming',
    ONLINE_GAMES: 'Online games',
    WEB_BROWSING: 'Web browsing',
    TRANSACTION_SERVICES: 'Transaction services',
    COMMAND_CONTROL: 'Command/control',
    GROUP_WORK_SERVICES: 'Services for group work (virtual boards, online graphics, etc.)',
    STILL_IMAGE: 'Still image',
    EMAIL: 'E-mail',
    SPECIALIZED_SOFTWARE: 'Specialized software',
    CONVERSATIONAL_VOICE: 'Conversational voice',

    TRAFFIC_PROFILE: {
        BASIC: {
            NAME: 'Basic',
            DESCRIPTIONS: {
                LOW: 'Users in hard-to-reach areas (including rural areas) in the countries where the internal backbone is not developed yet and/or the external connectivity to the international communication channels is poor, with low intensity of service usage',
                MEDIUM: 'Users in hard-to-reach areas (including rural areas) in the countries where the internal backbone is not developed yet and/or the external connectivity to the international communication channels is poor, with medium intensity of service usage',
                HIGH: 'Users in hard-to-reach areas (including rural areas) in the countries where the internal backbone is not developed yet and/or the external connectivity to the international communication channels is poor, with high intensity of service usage'
            }
        },
        INTERMEDIATE: {
            NAME: 'Intermediate',
            DESCRIPTIONS: {
                LOW: 'Users in developing countries with average broadband coverage and more than one external international communication channel, with low intensity of service usage',
                MEDIUM: 'Users in developing countries with average broadband coverage and more than one external international communication channel, with medium intensity of service usage',
                HIGH: 'Users in developing countries with average broadband coverage and more, than one external international communication channel, with high intensity of service usage'
            }
        },
        ADVANCED: {
            NAME: 'Advanced',
            DESCRIPTIONS: {
                LOW: 'Users in developed countries with good broadband coverage and international communication channels, with low intensity of service usage',
                MEDIUM: 'Users in developed countries with good broadband coverage and international communication channels, with medium intensity of service usage',
                HIGH: 'Users in developed countries with good broadband coverage and international communication channels, with high intensity of service usage'
            }
        }
    }
};

const ru = {
    DESCRIPTIONS: {
        TITLE: 'Калькулятор пропускной способности',
        SUBTITLE: 'Чтобы расчитать пропускную способность, выберите тип объекта, количество пользователей, уровни качества и интенсивности. Переключитесь в расширенный режим, чтобы выбирать используемые сервисы и в продвинутый режим, чтобы установить параметры вручную.',
        ESTIMATED_BANDWIDTH: 'Оценка пропускной способности',
        ESTIMATE_BANDWIDTH_FOR: 'Расчитать пропускную способность для',
        CALCULATION_MODE: 'Режим',
        SCOPE: 'Группа',
        OBJECT_TYPE: 'Тип объекта',
        NUMBER_OF_USERS: 'Количество пользователей',
        HOW_IT_WORKS: 'Как это работает',
        ADD_CUSTOM_SERVICE: 'Добавить сервис',
        QUALITY_LEVEL: 'Уровень качества',
        INTENSITY_LEVEL: 'Уровень интенсивности',
        LOW: 'Низкий',
        MEDIUM: 'Средний',
        HIGH: 'Высокий',
        QUALITY: 'Качество',
        INTENSITY: 'Интенсивность',
        TRAFFIC_PROFILE: 'Профиль использования трафика',
        SERVICES: 'Сервисы'
    },
    SERVICES: {
        CONVERSATIONAL_VOICE: 'Раз',
        SPECIALIZED_SOFTWARE: 'Specialized software'
    },
    OBJECTS: {
        RURAL_OBJECT: 'Rural Object',
        URBAN_OBJECT: 'Urban Object',
        OFFICE: 'Office',
    },
    CALCULATION_MODES: {
        SIMPLE: 'Простой',
        ADVANCED: 'Продвинутый',
        EXTENDED: 'Расширенный'
    },
    SERVICE_CARD: {

    },
    CONTROLS: {
        SEND_DATA: 'Отправить данные',
        SAVE_AS_PDF: 'Сохранить как PDF',
        SAVE_AS_CSV: 'Сохранить как CSV',
        CLEAR_SETTINGS: 'Сбросить настройки'
    },
    SHARE_FORM: {
        SHARE_DATA_WITH_DEV: 'Отправьте ваши данные разработчикам',
        EMAIL: 'Электронный адрес',
        MESSAGE: 'Сообщение',
        LEAVE_YOUR_FEEDBACK: 'Оставьте ваше сообщение',
        HELP_TO_IMPROVE: 'Помогите нам улучшить наш сервис. Поделитесь своими данными с нами, чтобы мы могли улучшить параметры для более точного расчета.',
        OK: 'Отправить',
        CANCEL: 'Отменить'
    },
    REPORT: {
        HEADER: 'Результат расчета',
        ESTIMATED_BANDWIDTH: 'Оценка пропускной способности',
        SELECTED_SCOPE: 'Выбранная группа',
        SELECTED_OBJECT: 'Выбранный тип объекта',
        SETTINGS: 'Выбранные настройки',
        ACTIVE_SERVICES: 'Активные сервисы',    
    },
    HOUSEHOLDS: 'Дом',
    EDUCATION_SCIENCE: 'Образование и наука',
    MEDICINE: 'Медицина',
    BUSINESS: 'Бизнес',
    INDUSTRY: 'Индустрия',
    ADMINISTRATIVE: 'Администрация',
    CULTURE_SPORT: 'Культура и спорт',
    TRANSPORT: 'Транспорт',
    VEHICLE_REPAIR: 'Станция тех обслуживания',
    BUS_STATION: 'Автостанция',
    GAS_STATION: 'Заправка',
    MUSEUM: 'Музей',
    CITY_PARK: 'Городской парк',
    RURAL_OBJECT: 'Сельская месность',
    URBAN_OBJECT: 'Городская месность',
    SCHOOL: 'Школа',
    UNIVERSITY: 'Университет',
    RND_OFFICE: 'Центр разработки',
    HOSPITAL: 'Госпиталь',
    MEDICAL_CENTER: 'Медицинский центр',
    STORE: 'Магазин',
    SUPERMARKET: 'Супермаркет',
    BAKERY: 'Пекарня',
    IT_OFFICE: 'IT Офис',
    BANK: 'Банк',
    FARM: 'Ферма',
    RESTAURANT: 'Ресторан',
    HOTEL: 'Отель',
    WAREHOUSE: 'Склад',
    FACTORY: 'Фабрика',
    POST_OFFICE: 'Почта',
    ADMINISTRATION_OBJECT: 'Административное здание',
    THEATRE: 'Театр',
    STADIUM: 'Стадион',
    DATA_TRANSFER_RETRIEVAL: 'Передача данных',
    HIGH_QUALITY_STREAMING_AUDIO: 'Высококачественное аудио',
    VIDEO_CONFERENCE: 'Видеоконференция',
    BROADCAST: 'Broadcast',
    VIDEO_STREAMING: 'Видеостриминг',
    ONLINE_GAMES: 'Онлайн игры',
    WEB_BROWSING: 'Веб серфинг',
    TRANSACTION_SERVICES: 'Транзакционные сервисы',
    COMMAND_CONTROL: 'Удаленное управление',
    GROUP_WORK_SERVICES: 'Сервисы для групповой работы',
    STILL_IMAGE: 'Передача изображений',
    EMAIL: 'Почта',
    SPECIALIZED_SOFTWARE: 'Специализированное ПО',
    CONVERSATIONAL_VOICE: 'Аудиозвонки',

    TRAFFIC_PROFILE: {
        BASIC: {
            NAME: 'Базовый',
            DESCRIPTIONS: {
                LOW: 'Пользователи в труднодоступных местах(включая сельскую местность) в странах с низкоразвитым доступом к Интернет с низким уровнем интенсивности использования сервисов.',
                MEDIUM: 'Пользователи в труднодоступных местах(включая сельскую местность) в странах с низкоразвитым доступом к Интернет с средним уровнем интенсивности использования сервисов.',
                HIGH: 'Пользователи в труднодоступных местах(включая сельскую местность) в странах с низкоразвитым доступом к Интернет с высоким уровнем интенсивности использования сервисов.'
            }
        },
        INTERMEDIATE: {
            NAME: 'Средний',
            DESCRIPTIONS: {
                LOW: 'Пользователи в развивающихся странах с среднеразвитым доступом к интернет и более чем 1 внешним каналом связи с низким уровнем интенсивности использования сервисов.',
                MEDIUM: 'Пользователи в развивающихся странах с среднеразвитым доступом к интернет и более чем 1 внешним каналом связи с средним уровнем интенсивности использования сервисов.',
                HIGH: 'Пользователи в развивающихся странах с среднеразвитым доступом к интернет и более чем 1 внешним каналом связи с высоким уровнем интенсивности использования сервисов.'
            }
        },
        ADVANCED: {
            NAME: 'Продвинутый',
            DESCRIPTIONS: {
                LOW: 'Пользователи в развитых странах с хорошим широкополосным покрытием с низким уровнем использования сервисов.',
                MEDIUM: 'Пользователи в развитых странах с хорошим широкополосным покрытием с средним уровнем использования сервисов.',
                HIGH: 'Пользователи в развитых странах с хорошим широкополосным покрытием с высоким уровнем использования сервисов.'
            }
        }
    }
};

module.exports = {
    en,
    ru,
}