<template>
    <div traffic-profile-picker>
      <p><b>{{$t('DESCRIPTIONS.TRAFFIC_PROFILE')}}</b></p>
      <div class="profile-container">
          <a-radio-group :default-value="basicMinus" v-model="checked" @change="onChange" button-style="solid">
            <div class="table">
                <div class="row">
                  <div class="cell"></div>
                  <a-radio-button class="red cell" :value="basicMinus">{{$t('TRAFFIC_PROFILE.BASIC.NAME')}}-</a-radio-button>
                  <a-radio-button class="red cell" :value="basic">{{$t('TRAFFIC_PROFILE.BASIC.NAME')}}</a-radio-button>
                  <a-radio-button class="red cell" :value="basicPlus">{{$t('TRAFFIC_PROFILE.BASIC.NAME')}}+</a-radio-button>
                </div>
                <div class="row">
                  <div class="cell"></div>
                  <a-radio-button class="yellow cell" :value="intermediateMinus">{{$t('TRAFFIC_PROFILE.INTERMEDIATE.NAME')}}-</a-radio-button>
                  <a-radio-button class="yellow cell" :value="intermediate">{{$t('TRAFFIC_PROFILE.INTERMEDIATE.NAME')}}</a-radio-button>
                  <a-radio-button class="yellow cell" :value="intermediatePlus">{{$t('TRAFFIC_PROFILE.INTERMEDIATE.NAME')}}+</a-radio-button>
                </div>
                <div class="row">
                  <div class="cell"></div>
                  <a-radio-button class="green cell" :value="advancedMinus">{{$t('TRAFFIC_PROFILE.ADVANCED.NAME')}}-</a-radio-button>
                  <a-radio-button class="green cell" :value="advanced">{{$t('TRAFFIC_PROFILE.ADVANCED.NAME')}}</a-radio-button>
                  <a-radio-button class="green cell" :value="advancedPlus">{{$t('TRAFFIC_PROFILE.ADVANCED.NAME')}}+</a-radio-button>
                </div>
            </div>
          </a-radio-group>
      <div class="profile-description">
        <div><b>{{$t('DESCRIPTIONS.QUALITY_LEVEL')}}: {{checked ? $t(`DESCRIPTIONS.${levels[checked.quality]}`) : $t(`DESCRIPTIONS.${levels[0]}`)}}</b></div>
        <div><b>{{$t('DESCRIPTIONS.INTENSITY_LEVEL')}}: {{checked ? $t(`DESCRIPTIONS.${levels[checked.intensity]}`) : $t(`DESCRIPTIONS.${levels[0]}`)}}</b></div>
        {{profileDescription}}
      </div>
    </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    name: 'TrafficProfilePicker',
    model: {
      prop: 'selectedTrafficProfile',
      event: 'change'
    },
    data() {
        return {
            basicMinus: {
              quality: 0,
              intensity: 0
            },
            basic: {
              quality: 0,
              intensity: 1,
            },
            basicPlus: {
              quality: 0,
              intensity: 2,
            },
            intermediateMinus: {
              quality: 1,
              intensity: 0,
            },
            intermediate: {
              quality: 1,
              intensity: 1
            },
            intermediatePlus: {
              quality: 1,
              intensity: 2,
            },
            advancedMinus: {
              quality: 2,
              intensity: 0
            },
            advanced: {
              quality: 2,
              intensity: 1
            },
            advancedPlus: {
              quality: 2,
              intensity: 2
            },
            checked: this.basicMinus,
            profiles: ['BASIC', 'INTERMEDIATE', 'ADVANCED'],
            levels: ['LOW', 'MEDIUM', 'HIGH']
        };
    },
    mounted () {
      this.$root.$on('clearSettings', this.onClearSettings);
    },
    beforeDestroy () {
      this.$root.$off('clearSettings', this.onClearSettings);
    },
    methods: {
      ...mapActions(['calculateActiveServices']),
      onChange() {
        this.$store.commit('SET_TRAFFIC_PROFILE', this.checked);
        this.calculateActiveServices();
      },
      onClearSettings() {
        this.checked = this.basicMinus;
      }
    },
    computed: {
      ...mapGetters(['trafficProfile', 'activeServices']),
      profileDescription() {
        let translationKey = 'TRAFFIC_PROFILE.BASIC.DESCRIPTIONS.LOW';
        if(this.checked) {
          const {quality, intensity} = this.checked;

          translationKey = `TRAFFIC_PROFILE.${this.profiles[quality]}.DESCRIPTIONS.${this.levels[intensity]}`
        }
        return this.$t(translationKey)
      }
    }
}
</script>

<style scoped>


.vertical {
  transform: rotate(-90deg);
  display: inline-block;
}

.profile-container {
  display: flex;
  max-width: 50vw;
  gap: 1rem;
}

@media (max-width: 80rem) {
  .profile-container {
    flex-direction: column;
    max-width: 100%;
  }
}

.label {
  text-align: center;
}

.profile-description {
  text-align: justify;
}

td label{
  padding-top: 0.3rem;
  padding-left: 1rem;
  margin: 0.5rem;
  height: 2.5rem;
  width: 7rem;
}

.table {
  display: inline-block;
  border-spacing: 0.3rem;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
}

.green {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.yellow {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.red {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.ant-radio-button-wrapper-checked {
  background: teal !important;
}


.ant-radio-button-wrapper-checked.green {
  background: #28a745 !important;
  border-color: #28a745 !important;
}

.ant-radio-button-wrapper-checked.yellow {
  background: #ffc107 !important;
  border-color: #ffc107 !important;
}

.ant-radio-button-wrapper:not(:first-child).green {
    border-left: 1px solid #28a745;

}

.ant-radio-button-wrapper:not(:first-child).yellow {
    border-left: 1px solid #ffc107;

}.ant-radio-button-wrapper:not(:first-child).red {
    border-left: 1px solid #dc3545;

}

.ant-radio-button-wrapper-checked.red {
  background: #dc3545 !important;
  border-color: #dc3545 !important;
}

</style>