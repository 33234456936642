<template>
  <div id="app">
    <a-layout id="bandwidth-calculator">
      <div class="page">
        <top-bar />
        <a-layout-content class="content">
          <bandwidth-calculator/>
        </a-layout-content>
      <bottom-bar />
      </div>
    </a-layout>
  </div>
</template>

<script>
import BandwidthCalculator from '@/pages/BandwidthCalculator/BandwidthCalculator'
import TopBar from '@/components/TopBar.vue'
import BottomBar from '@/components/BottomBar.vue'

export default {
  name: 'App',
  components: {
    BandwidthCalculator,
    TopBar,
    BottomBar
  }
}
</script>

<style>
#app {
  font-family: Roboto,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 1.1rem;
}

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  padding: 0 3vw;
  flex: 1;
}

.ant-layout-header {
  background: #f8f8f8 !important;
}

.ant-layout-content {
  background: #ffffff;
}

.ant-layout-footer {
  background: #ffffff !important;
  position: page;
  bottom: 0px;
  width: 100%;
}

.ant-layout {
  background: #ffffff !important;
}

.ant-btn-primary:not(:disabled) {
  background-color: #428bca !important;
  border-color: #357ebd !important;
}
.ant-btn-link {
  color: #777 !important;
}

.ant-dropdown-link {
  color: #777 !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 50px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 50px !important;
}

.ant-radio-button-wrapper {
  border-radius: 50px;
  margin: 0.3rem !important;
}

.ant-radio-button-wrapper:not(:first-child) {
    border-left: 1px solid #d9d9d9;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px !important;
  border-radius: 50px !important;
}

.ant-radio-button-wrapper-checked {
  background: #428bca !important;
  color: #ffffff !important;
  border-color: #428bca !important;
}

.ant-card-body {
  padding: 15px !important;
}

.ant-input-group-addon {
  width: 7rem !important;
}

</style>
