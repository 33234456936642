import {Service} from '@/utils';

const getState = () => {
  return {
    selectedSettings: {
      scope: '',
      objectType: '',
      numberOfUsers: 1,
      calculationMode: 'ADVANCED',
      trafficProfile: {
        quality: 0,
        intensity: 0,
      }
    },
    activeServices: [],
    estimatedBandwidth: 0,
  }
}

const state = getState();

const getters = {
  estimatedBandwidth: (state) => state.estimatedBandwidth,
  activeServices: (state) => state.selectedSettings.objectType.services,
  selectedSettings: (state) => state.selectedSettings,
  numberOfUsers: (state) => state.selectedSettings.numberOfUsers,
  calculationMode: (state) => state.selectedSettings.calculationMode,
  trafficProfile: (state) => state.selectedSettings.trafficProfile,
  service: (state, name) => state.activeServices[name]
};

const actions = {
  calculateBandwidth: ({commit, state}) => {
    const estimatedBandwidth = Object.values(state.selectedSettings?.objectType?.services).reduce((acc, current) => acc+current.bandwidth, 0);
    commit('SET_ESTIMATED_BANDWIDTH', Math.ceil(estimatedBandwidth));
  },
  calculateActiveServices: ({commit, dispatch, state}) => {
    commit('SET_ACTIVE_SERVICES', state.selectedSettings?.objectType?.services);
    dispatch('calculateBandwidth');
  },
  resetState: ({commit, dispatch}) => {
    commit('RESET_STATE');
    dispatch('init');
  },
}


const mutations = {
  'SET_ESTIMATED_BANDWIDTH': (state, payload) => {
      state.estimatedBandwidth = payload;
  },
  'SET_SCOPE': (state, payload) => {
    state.selectedSettings.scope = payload
  },
  'SET_OBJECT_TYPE': (state, payload) => {
    state.selectedSettings.objectType = payload;
  },
  'SET_CALCULATION_MODE': (state, payload) => {
    state.selectedSettings.calculationMode = payload;
  },
  'SET_ACTIVE_SERVICES': (state, payload) => {
    state.activeServices = payload
  },
  'SET_SERVICE_STATUS': (state, payload) => {
    state.activeServices[payload.serviceName] = payload.status;
  },
  'SET_SERVICE_BANDWIDTH': (state, payload) => {
    state.activeServices[payload.serviceName] = payload.data;
  },
  'SET_SERVICE_DATA_TRANSFER_RATE': (state, payload) => {
    state.activeServices[payload.serviceName].dataTransferRate = payload.data;
  },

  'SET_SERVICE_INTENSITY_PER_USER': (state, payload) => {
    state.activeServices[payload.serviceName].intensityPerUser = payload.data;
  },
  'SET_SERVICE_LATENCY': (state, payload) => {
    state.activeServices[payload.serviceName].latency = payload.data;
  },
  'SET_SERVICE_DATA_PER_SESSION': (state, payload) => {
    state.activeServices[payload.serviceName].dataPerSession = payload.data;
  },

  'SET_SERVICE_DATA': (state, payload) => {
    state.activeServices[payload.serviceName].serviceData = payload.serviceData;
  },

  'SET_TRAFFIC_PROFILE': (state, payload) => {
    state.selectedSettings.trafficProfile = payload;

    for (const service of state.selectedSettings.objectType.services) {
      service.trafficProfile = payload
    }
  },
  'SET_USER_NUMBER': (state, payload) => {
    state.selectedSettings.numberOfUsers = payload;
    Object.values(state.activeServices).map((service) => {
      service.numberOfUsers = payload;
    })
  },
  'RESET_STATE': (state) => {   
    Object.assign(state, getState());
  },
  'ADD_SERVICE': (state, payload) => {
    const newService = new Service({
      name: payload.name,
      dataTransferRate: payload.dataTransferRate,
      latency: payload.latency,
      intensityPerUser: payload.intensityPerUser,
      dataPerSession: payload.dataPerSession,
      expectedDegradationProbabilities: payload.expectedDegradationProbabilities,
    })

    state.selectedSettings.objectType.addCustomService(newService)

  }
}

export default {
  state,
  getters,
  actions,
  mutations
};