<template>
    <div class="estimated-bandwidth">
        <p class="disclaimer">
          Please note that you are accessing a beta  version of this software. Access to this beta version is for purposes of testing and improvement of the software. Please input or adjust the data to continue.
        </p>
      <div>{{$t('DESCRIPTIONS.ESTIMATED_BANDWIDTH')}}:
        <br>
        <span class="green">{{estimatedBandwidth}} Mbps</span>
      </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'EstimatedBandwidth',
    data() {
        return {}
    },
    computed: {
      ...mapGetters(['estimatedBandwidth'])
    }
}
</script>
<style>

.estimated-bandwidth {
  padding: 0.3em;
  margin-bottom: 1rem;
  text-align: center;
  background: rgba(242, 242, 242, 1);
  position: sticky;
  top: 0px;
  z-index: 999;
  font-size: 30px;
}
.green {
  color: #008080;
}
.disclaimer {
  background: rgb(255, 227, 182);
  font-size: 1rem;
  border-radius: 1rem;
  padding: 0.5rem;
}


</style>
